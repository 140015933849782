import { Paper, Stack, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";

export default function GuessItem({ guess, direction, difference }) {
  let icon = null;
  let bgcolor = "warmth.cold";
  let color = "common.white";
  if (difference > 5) {
    bgcolor = "warmth.cold";
  } else if (difference >= 2) {
    bgcolor = "warmth.warm";
  } else if (difference >= 1) {
    bgcolor = "warmth.warmer";
  }
  switch (direction) {
    case "down":
      icon = <ArrowDownwardIcon />;
      break;
    case "up":
      icon = <ArrowUpwardIcon />;
      break;
    case "correct":
      icon = <CheckIcon />;
      color = "common.white";
      bgcolor = "warmth.success";
      break;
  }
  return (
    <Paper elevation={1} sx={{ bgcolor, color, width: "max-content" }}>
      <Stack direction="row" spacing={1} sx={{ p: 1 }}>
        {icon}
        <Typography>{guess}%</Typography>
      </Stack>
    </Paper>
  );
}
