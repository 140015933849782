import { Stack } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import mobile from "is-mobile";

import "./App.css";

import Game from "screens/Game";
import Ad from "components/Ad";
import NavBar from "components/NavBar";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Hind Siliguri', sans-serif",
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontFamily: "Quicksand",
    },
    h7: {
      fontWeight: 600,
    },
  },
  palette: {
    background: {
      paper: "#fff",
      guess: "#d1e7ff",
    },
    warmth: {
      cold: "#a93c3c",
      warm: "#b97c2c",
      warmer: "#98b136",
      success: "#34a042",
    },
    text: {
      primary: "#000000",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
  },
});

function App() {
  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Stack
                  direction="column"
                  alignItems="center"
                  sx={{ width: "100vw", overflowX: "none" }}
                >
                  <NavBar />
                  <Stack direction={{ xs: "column", md: "row" }}>
                    {!mobile() && <Ad />}
                    <Game />
                    {!mobile() && <Ad />}
                  </Stack>
                </Stack>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
