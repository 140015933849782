import { Stack, Box, Typography } from "@mui/material";

import Card from "components/Card";

export default function Hand({ player, guess, showAnswer, playerType }) {
  const { hand, percentage } = player;

  let percentageDisplay = "??%";
  if (showAnswer) {
    percentageDisplay = (percentage ?? 0) + "%";
  } else if (guess && playerType === "hero") {
    percentageDisplay = guess + "%";
  }

  const heroColor = showAnswer ? "warmth.success" : "info.main";

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1}>
        {hand.map((card) => (
          <Card key={card} card={card} />
        ))}
      </Stack>
      <Box
        sx={{
          bgcolor: playerType === "hero" ? heroColor : "text.secondary",
        }}
      >
        <Typography variant="subtitle1" align="center" color="common.white">
          {playerType === "hero" ? "Hero" : "Villain"}
          {": "}
          {percentageDisplay}
        </Typography>
      </Box>
    </Stack>
  );
}
