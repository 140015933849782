import { Paper, Typography, Box, Stack } from "@mui/material";

import { getSuitImage } from "utils/poker";

export default function Card({ card }) {
  const letter = card.split("")[0];
  const suit = card.split("")[1];
  return (
    <Paper elevation={3} sx={{ width: { xs: "7vmax", md: "4.5vmax" } }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack direction="column" spacing={1} sx={{ padding: 1 }}>
          <Typography
            sx={{
              height: { xs: "5vmax", md: "3vmax" },
              fontSize: {
                xs: "5vmax",
                md: "3vmax",
              },
            }}
            variant="h3"
            align="center"
          >
            {letter}
          </Typography>
          <Box
            component="img"
            sx={{
              height: { xs: "3.5vmax", md: "2vmax" },
              objectFit: "contain",
            }}
            src={getSuitImage(suit)}
          />
        </Stack>
      </Box>
    </Paper>
  );
}
