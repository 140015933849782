export function encodeGame(hands, board) {
  return hands.map((hand) => hand.join("x")).join("-") + "_" + board.join("x");
}

export function decodeGame(str) {
  const hands = str
    .split("_")[0]
    .split("-")
    .map((handStr) => handStr.split("x"));
  let board = str.split("_")[1].split("x");
  if (board.length == 1 && board[0].length == 0) {
    board = [];
  }
  return { hands, board };
}
