import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Typography,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";

export default function NavBar() {
  const theme = useTheme();
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  return (
    <>
      <Stack
        direction="row"
        sx={{
          fontFamily: "Quicksand",
          pt: 1,
          width: "100vw",
          p: 1,
          mb: 2,
        }}
        justifyContent="center"
      >
        <Box
          sx={{ position: "relative" }}
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Game Info">
            <IconButton
              onClick={() => setShowHelpDialog(true)}
              sx={{ position: "absolute", left: "-" + theme.spacing(7) }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
          <Stack direction="row">
            <Typography variant="h4">Poker</Typography>
            <Typography variant="h4" color="error.main">
              Percent
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <HelpDialog
        open={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
      />
    </>
  );
}

function HelpDialog({ open, onClose }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>Game Info</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography variant="h7">How to play</Typography>
        <Typography gutterBottom>
          The goal of the game is simple: <b>Guess the Hero's win percentage</b>{" "}
          - how likely it is that their hand will be strongest by showdown.
          <br />
          Every time you make an incorrect guess, an arrow will be shown next to
          the guess, indicating whether you should go higher or lower. The color
          indicates how close you are:
          <ul>
            <li>
              <Stack direction="row" alignItems="center">
                More than 5% away
                <Box
                  sx={{ bgcolor: "warmth.cold", width: 20, height: 20, ml: 1 }}
                ></Box>
              </Stack>
            </li>
            <li>
              <Stack direction="row" alignItems="center">
                2-5% away
                <Box
                  sx={{ bgcolor: "warmth.warm", width: 20, height: 20, ml: 1 }}
                ></Box>
              </Stack>
            </li>
            <li>
              <Stack direction="row" alignItems="center">
                1% away
                <Box
                  sx={{
                    bgcolor: "warmth.warmer",
                    width: 20,
                    height: 20,
                    ml: 1,
                  }}
                ></Box>
              </Stack>
            </li>
          </ul>
        </Typography>
        <Typography variant="h7">Try different scenarios</Typography>
        <Typography gutterBottom>
          Change the options at the top to set the board state and the number of
          players in the hand.
        </Typography>
        <Typography variant="h7">Hand selection</Typography>
        <Typography gutterBottom>
          To make the game more realistic and give better practice for
          situations you might face in real hands, we trim some bad hands from
          being shown. The criteria for a hand to be allowed:
          <ul>
            <li>Any pocket pair</li>
            <li>Any suited cards</li>
            <li>Any two Broadways</li>
            <li>Any connected cards</li>
          </ul>
          No non-qualifying hands will be in the game (see ya, 72o).
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
