import { useEffect } from "react";
import { Box } from "@mui/material";
import AdSense from "react-adsense";

export default function Ad() {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "inline-block", width: "300px", height: "600px" }}
      data-ad-client="ca-pub-5394488677191018"
      data-ad-slot="6681437383"
    ></ins>
  );
}
