import { useState, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Stack,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Snackbar,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useCookies } from "react-cookie";

import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useSearchParams } from "react-router-dom";

import { log } from "utils/logger";
import { cardToText } from "utils/text";
import { encodeGame, decodeGame } from "utils/url";
import { getPlayersAndBoard, getPlayersAndBoardOverride } from "utils/poker";

import Hand from "components/Hand";
import Card from "components/Card";
import GuessItem from "components/GuessItem";
import GuessInput from "components/GuessInput";

const PLAYER_COUNT_OPTIONS = [2, 3, 4, 5];
const BOARD_OPTIONS = [
  { name: "Preflop", value: 0 },
  { name: "Flop", value: 3 },
  { name: "Turn", value: 4 },
];

export default function Game() {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [cookies, setCookie] = useCookies(["info_alert"]);

  const [players, setPlayers] = useState([]);
  const [playerCount, setPlayerCount] = useState(2);
  const [board, setBoard] = useState([]);
  const [boardCount, setBoardCount] = useState(0);
  const [guess, setGuess] = useState(50);
  const [guessHistory, setGuessHistory] = useState([]);
  const [isCorrect, setIsCorrect] = useState(false);
  const [madeChange, setMadeChange] = useState(false);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onRestart = async (
    numPlayers,
    numBoard,
    playersOverride,
    boardOverride
  ) => {
    setIsLoading(true);
    setTimeout(() => {
      if (playersOverride != null && boardOverride != null) {
        setPlayers(playersOverride);
        setBoard(boardOverride);
        setPlayerCount(playersOverride.length);
        setBoardCount(boardOverride.length);
        setMadeChange(true);
      } else {
        const { players, board } = getPlayersAndBoard(
          numPlayers ?? playerCount,
          numBoard ?? boardCount
        );
        setPlayers(players);
        setBoard(board);
      }
      setIsCorrect(false);
      setGuess(50);
      setGuessHistory([]);
      setIsLoading(false);
    }, 0);
  };

  const generateGame = (numPlayers, numBoard) => {
    onRestart(numPlayers, numBoard);
  };

  useEffect(() => {
    setSearchParams({
      game: encodeGame(
        players.map((player) => player.hand),
        board
      ),
    });
  }, [players, board]);

  useEffect(() => {
    const gameUrl = searchParams.get("game");
    if (gameUrl != null) {
      try {
        const { hands, board } = decodeGame(gameUrl);
        const { players } = getPlayersAndBoardOverride(hands, board);
        onRestart(0, 0, players, board);
      } catch (e) {
        onRestart();
      }
    } else {
      onRestart();
    }
  }, []);

  const onGuessSubmit = () => {
    if (guess == null || players.length == 0 || isCorrect) {
      return;
    }
    if (guess > 100) {
      return;
    }
    if (
      guessHistory.map((previousGuess) => previousGuess.guess).includes(guess)
    ) {
      return;
    }
    log("submit_guess");

    const correctAnswer = players[0].percentage;
    const difference = Math.abs(correctAnswer - guess);
    if (correctAnswer === guess) {
      setIsCorrect(true);
      setGuessHistory([...guessHistory, { guess, direction: "correct" }]);
    } else if (correctAnswer < guess) {
      setGuessHistory([
        ...guessHistory,
        { guess, direction: "down", difference },
      ]);
    } else if (correctAnswer > guess) {
      setGuessHistory([
        ...guessHistory,
        { guess, direction: "up", difference },
      ]);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onGuessSubmit();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [onGuessSubmit]);

  const copyGame = () => {
    log("copy_game");
    navigator.clipboard.writeText(window.location.href);
    setCopySnackbarOpen(true);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Stack direction="column" alignItems="center">
        {cookies.info_alert === "shown" ? null : (
          <InfoAlert setShown={() => setCookie("info_alert", "shown")} />
        )}
        <Stack direction="row" spacing={1} sx={{ mb: 2 }} alignItems="center">
          <Tooltip title="New Game">
            <IconButton
              onClick={() => {
                log("new_game");
                onRestart();
              }}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
          <FormControl sx={{ m: 1 }}>
            <InputLabel>Players</InputLabel>
            <Select
              value={playerCount}
              label="Players"
              onChange={(e) => {
                log("option_change_player_count", e.target.value);
                setPlayerCount(e.target.value);
                generateGame(e.target.value, boardCount);
              }}
            >
              {PLAYER_COUNT_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option + " players"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1 }}>
            <InputLabel>Board</InputLabel>
            <Select
              value={boardCount}
              label="Board"
              onChange={(e) => {
                log("option_change_board_count", e.target.value);
                setBoardCount(e.target.value);
                generateGame(playerCount, e.target.value);
              }}
            >
              {BOARD_OPTIONS.map((option) => (
                <MenuItem key={option.name} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title="Copy Link">
            <IconButton onClick={() => copyGame()}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {board.length > 0 && (
              <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                {board.map((card) => (
                  <Card key={card} card={card} />
                ))}
              </Stack>
            )}
            <Stack
              spacing={{ xs: 2, md: 5 }}
              direction={{ xs: "column", md: "row" }}
            >
              {players.map((player, index) => (
                <Hand
                  key={"hand" + index}
                  playerType={index === 0 ? "hero" : "villain"}
                  player={player}
                  guess={parseInt(guess)}
                  showAnswer={isCorrect}
                />
              ))}
            </Stack>
          </>
        )}
        <GuessInput guess={guess} setGuess={setGuess} isDisabled={isCorrect} />
        {isCorrect ? (
          <Button
            sx={{ mt: 2, width: "max-content" }}
            variant="contained"
            endIcon={<ReplayIcon />}
            onClick={() => {
              log("play_again");
              onRestart();
            }}
          >
            Play Again
          </Button>
        ) : (
          <Button
            sx={{ mt: 1, width: "max-content" }}
            variant="contained"
            disabled={isCorrect}
            onClick={onGuessSubmit}
          >
            Guess
          </Button>
        )}
        {isCorrect && (
          <ShareAlert
            hands={players.map((player) => player.hand)}
            board={board}
            guessHistory={guessHistory}
            openCopySnackbar={() => setCopySnackbarOpen(true)}
          />
        )}
        <Stack
          spacing={1}
          direction="column"
          sx={{ mt: 2, mb: 2 }}
          alignItems="center"
        >
          {guessHistory.map((previousGuess) => {
            return <GuessItem key={previousGuess.guess} {...previousGuess} />;
          })}
        </Stack>
      </Stack>
      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setCopySnackbarOpen(false)}
        message="Copied to clipboard!"
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setCopySnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
}

function InfoAlert({ setShown }) {
  return (
    <Alert
      severity="info"
      sx={{ mb: 2 }}
      action={
        <IconButton size="small" color="inherit" onClick={setShown}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      Figure out the Hero's <b>win</b>% - use the slider or buttons to make your
      guess!
      <Stack direction="row" spacing={2}>
        <Stack direction="row" alignItems="center">
          {">"}5% away:
          <Box
            sx={{ bgcolor: "warmth.cold", width: 20, height: 20, ml: 0.5 }}
          ></Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          2-5% away:
          <Box
            sx={{ bgcolor: "warmth.warm", width: 20, height: 20, ml: 0.5 }}
          ></Box>
        </Stack>
        <Stack direction="row" alignItems="center">
          1% away:
          <Box
            sx={{
              bgcolor: "warmth.warmer",
              width: 20,
              height: 20,
              ml: 0.5,
            }}
          ></Box>
        </Stack>
      </Stack>
    </Alert>
  );
}

function ShareAlert({ hands, board, guessHistory, openCopySnackbar }) {
  const guessText = guessHistory
    .map(({ difference }) => {
      if (difference > 5) {
        return "🟥";
      } else if (difference >= 2) {
        return "🟧";
      } else if (difference >= 1) {
        return "🟨";
      }
      return "🟩";
    })
    .join("");
  const handsText = hands
    .map(
      (hand, index) =>
        (index == 0 ? "Hero: " : "V: ") +
        hand.map((card) => cardToText(card)).join("")
    )
    .join(" ");
  const boardText = board.map((card) => cardToText(card)).join(" ");

  let text =
    "I solved this PokerPercent puzzle in " +
    guessHistory.length +
    " " +
    (guessHistory.length > 1 ? "tries" : "try") +
    "!" +
    "\n";
  text += handsText + (board.length > 0 ? " Board: " + boardText : "") + "\n";
  text += guessText + "\n\n";
  text += window.location.href;

  return (
    <Alert severity="success" sx={{ mt: 2 }}>
      <AlertTitle>Well Done!</AlertTitle>
      <Box sx={{ whiteSpace: "pre-wrap" }}>{text}</Box>
      <Stack direction="row">
        <Button
          sx={{ mt: 2, width: "max-content" }}
          variant="outlined"
          endIcon={<ContentCopyIcon />}
          onClick={() => {
            log("copy_win_message");
            navigator.clipboard.writeText(text);
            openCopySnackbar();
          }}
        >
          Copy Text
        </Button>
      </Stack>
    </Alert>
  );
}
