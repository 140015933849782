import { Stack, Typography, Slider, IconButton } from "@mui/material";
import mobile from "is-mobile";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function GuessInput({ guess, setGuess, isDisabled }) {
  return (
    <Stack
      direction="column"
      sx={{ pt: 1 }}
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h5" align="center" sx={{ pt: 2, pb: 0, m: 0 }}>
        Guess: {guess}%
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          disabled={isDisabled}
          onClick={() => setGuess(Math.max(0, guess - 1))}
        >
          <RemoveIcon />
        </IconButton>
        <Slider
          disabled={isDisabled}
          value={guess}
          defaultValue={0}
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={100}
          onChange={(e) => {
            if (e.type === "mousedown" && mobile()) {
              return;
            }
            setGuess(e.target.value);
          }}
          sx={{ width: { xs: "60vw", md: "20vmax" } }}
        />
        <IconButton
          disabled={isDisabled}
          onClick={() => setGuess(Math.min(100, guess + 1))}
        >
          <AddIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
