function suitToEmoji(suit) {
  switch (suit) {
    case "h":
      return "♥️";
    case "d":
      return "♦️";
    case "s":
      return "♠️";
    case "c":
      return "♣️";
  }
}

export function cardToText(card) {
  const number = card.split("")[0];
  const suit = card.split("")[1];
  return number + suitToEmoji(suit);
}
