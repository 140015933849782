import { sample, difference } from "underscore";
import { TexasHoldem } from "poker-odds-calc";

import diamond from "images/diamond.png";
import club from "images/club.png";
import heart from "images/heart.png";
import spade from "images/spade.png";

const SUITS = ["s", "c", "d", "h"];

function getOdds({ hands, board }) {
  const table = new TexasHoldem();
  hands.forEach((hand) => {
    table.addPlayer(hand);
  });
  if (board.length >= 3) {
    table.setBoard(board);
  }
  const result = table.calculate();

  return result.getPlayers().map((player) => {
    return {
      hand: sortHand(player.getHand().match(/.{1,2}/g)),
      percentage: Math.round(player.getWinsPercentage()),
    };
  });
}

export function getPlayersAndBoardOverride(hands, board) {
  return { players: getOdds({ hands, board }), board };
}

export function getPlayersAndBoard(numHands, numBoard = 0) {
  const cardsInPlay = getCardsInPlay(numHands, numBoard);
  return { players: getOdds(cardsInPlay), board: cardsInPlay.board };
}

function getCardsInPlay(numHands, numBoard) {
  const hands = [];
  let deck = getDeck();

  let hand = sample(deck, 2);
  while (hands.length < numHands) {
    if (filterHands(hand) != null) {
      hands.push(hand);
      deck = difference(deck, hand);
    }
    hand = sample(deck, 2);
  }

  return { hands, board: sample(deck, numBoard) };
}

export function getSuitImage(suit) {
  switch (suit) {
    case "d":
      return diamond;
    case "s":
      return spade;
    case "c":
      return club;
    case "h":
      return heart;
  }
}

function getDeck() {
  const deck = [];
  for (let i = 1; i < 14; i++) {
    SUITS.forEach((suit) => {
      deck.push(numberToCard(i) + suit);
    });
  }
  return deck;
}

function numberToCard(number) {
  if (number >= 2 && number <= 9) {
    return number;
  }
  switch (number) {
    case 1:
      return "A";
    case 10:
      return "T";
    case 11:
      return "J";
    case 12:
      return "Q";
    case 13:
      return "K";
  }
}

function cardToNumber(card) {
  if (isNaN(card)) {
    switch (card) {
      case "A":
        return 14;
      case "T":
        return 10;
      case "J":
        return 11;
      case "Q":
        return 12;
      case "K":
        return 13;
    }
  } else {
    return parseInt(card);
  }
}

function filterHands(hand) {
  const card1Number = cardToNumber(hand[0].split("")[0]);
  const card2Number = cardToNumber(hand[1].split("")[0]);

  const card1Suit = hand[0].split("")[1];
  const card2Suit = hand[1].split("")[1];

  if (card1Number >= 10 && card2Number >= 10) {
    return hand;
  }
  if (card1Suit == card2Suit) {
    return hand;
  }
  if (Math.abs(card1Number - card2Number) > 4) {
    return null;
  }
  if (Math.min(card1Number, card2Number) <= 6) {
    if (Math.abs(card1Number - card2Number) > 1) {
      return null;
    }
  }
  return hand;
}

function sortHand(hand) {
  const card1Number = cardToNumber(hand[0].split("")[0]);
  const card2Number = cardToNumber(hand[1].split("")[0]);

  if (card2Number > card1Number) {
    return [hand[1], hand[0]];
  }
  return hand;
}
